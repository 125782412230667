@import 'app/assets/styles/icons';

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  height: 100dvh;
  width: 100%;
  max-width: 480px;
  overflow: hidden;
}

input.search {
  width: 100%;
  height: 36px;
  background: #f4f4f4;
  border-radius: 12px;
  border: 0;
  padding: 8px 10px 8px 30px;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: #767676;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.search-container {
  position: relative;
  margin: 0 12px 20px;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 14px;
    height: 14px;
    background-image: url(/assets/search.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.no-results {
  margin: -16px 20px 20px;
  color: #000;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
}

.search-no-results {
  margin: 20px;
  color: #000;
  font-size: 17px;
  letter-spacing: 0px;
}

.latch-card {
  &.no-border {
    .latch-card-header {
      border-bottom: 0;
    }
  }
}

.latch-card {
  flex: 1 0 auto;
  position: relative;
  min-height: 100%;
  max-height: 100%;

  .latch-card-content {
    position: relative;
    overflow: auto;
  }

  .latch-card-content,
  .latch-card-header {
    padding: 0;
  }

  .latch-card-header {
    display: flex;
    flex-direction: column;

    .latch-card-actions {
      height: 28px;
      margin: 0 20px 36px;

      .latch-button-icon {
        margin-left: -9px;
      }
    }

    .latch-card-title {
      margin: 0 20px 20px;
      font-size: 35px;
      font-weight: 500;
      letter-spacing: 0;
    }
  }

  .latch-card-content {
    .latch-list {
      margin: 0 12px 12px;
      display: block;

      .latch-list-item {
        height: 96px;
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        margin-bottom: 10px;
        padding: 26px 20px 28px 32px;

        &:last-child {
          margin-bottom: 0;
        }

        .latch-list-item-title {
          color: #000;
          font-size: 17px;
          font-weight: bold;
          letter-spacing: -.2px;
        }
      }
    }
  }

  .latch-card-footer {
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 35px;

    .latch-button {
      color: #fff;
    }

    .latch-button,
    .latch-button-outline {
      width: 100%;
      height: 48px;
      font-size: 17px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;

      &[disabled] {
        opacity: 1;
        color: rgba(255, 255, 255, 0.25);
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .latch-list-item-description {
    color: #000;
    font-size: 15px;
    letter-spacing: 0px;

    &.error {
      color: #fe3a32;
    }

    &.hold {
      color: #0089ff;
    }
  }

  .price-per-slot {
    color: #767676;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0;
  }

  .latch-labeled-list-item-label {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
  }

  .latch-labeled-list-item {
    color: rgb(0, 0, 0);
    font-size: 17px;
    letter-spacing: 0px;
  }

  .body-text {
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;

    .latch-button-link {
      padding: 0;
    }
  }
}

.bookings-building {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;

  .latch-button-icon {
    margin-right: 10px;
  }

  .latch-expansion-panel-header {
    padding: 0 20px;
  }
}

.bookings-card-row {
  height: 72px;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  font-size: 17px;
  letter-spacing: 0px;

  * {
    width: 100%;
  }
}

.booking-divider {
  height: 36px;
  background: #f4f4f4;
  padding: 6px 20px 8px;
  margin-bottom: 20px;

  font-size: 15px;
  letter-spacing: 0px;
  line-height: 22px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.latch-radio {
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 50%;
  border: 1px solid #9b9b9b;

  &.selected {
    border: 7px solid #0089ff;
  }
}

.mobile {
  latch-loading {
    display: contents;
  }

  .latch-toggle-toggle {
    width: 44px;
    height: 26px;
    box-sizing: border-box;
    border-radius: 30.92px;
    background: #fff;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: inherit;
    margin: unset;
    position: relative;
    border: 1px solid #e5e5ea;

    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0px;
      top: 0px;
      background: #fff;

      border: 1px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15),
      0px 1px 1px 0px rgba(0, 0, 0, 0.16),
      0px 2px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      box-sizing: border-box;
    }

    &.latch-toggle-checked {
      background: #0089ff;
      border: 1px solid #0089ff;
      &::after {
        left: unset;
        right: 0px;
      }
    }
  }

  &.in-app {
    .latch-card-actions {
      height: 0;
      * {
        display: none;
      }
    }
  }

  .latch-dialog {
    border-radius: 14px;
  }

  .latch-confirmation-dialog-content {
    padding: 25px;
  }

  .latch-confirmation-dialog-title {
    color: rgb(0, 0, 0);
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    line-height: 25px;
  }

  .latch-confirmation-dialog-message {
    color: rgb(0, 0, 0);
    font-size: 15px;
    text-align: center;
    letter-spacing: 0px;
    line-height: 20px;
  }

  .latch-confirmation-dialog-actions {
    border-top: 1px solid #cecece;
    background-color: #fff;
    justify-content: center;
    padding: 0;
    flex-direction: row-reverse;
  }

  .latch-button-link,
  .latch-button {
    width: 100%;
    padding: 12px 12px 14px;
    color: rgb(0, 0, 0);
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    border-right: 1px solid #cecece;

    &.latch-danger {
      color: #fe3a32;
      background-color: unset;
    }
  }
}

.date-text {
  color: #9b9b9b;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 20px;
  padding: 20px 20px;
}
