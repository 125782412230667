@mixin icon($url, $width, $height) {
  width: $width;
  height: $height;
  background-image: url($url);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
}

.latch-icon-back {
  @include icon('../../../assets/back.png', 18px, 18px);
}

.latch-icon-back-inverted {
  @include icon('../../../assets/back.png', 18px, 18px);
  filter: invert(1);
}

.latch-icon-building {
  @include icon('../../../assets/building.png', 24px, 24px);
}

.latch-icon-right-arrow-blue {
  @include icon('../../../assets/right-arrow-blue.svg', 10px, 18px);
}

.latch-icon-arrow-up {
  @include icon('../../../assets/arrow-up.png', 17px, 10px);
}

.latch-icon-arrow-down {
  @include icon('../../../assets/arrow-down.png', 17px, 10px);
}

.latch-icon-arrow-left {
  @include icon('../../../assets/arrow-up.png', 17px, 10px);
  transform: rotate(-90deg);
}

.latch-icon-arrow-right {
  @include icon('../../../assets/arrow-up.png', 17px, 10px);
  transform: rotate(90deg);
}
